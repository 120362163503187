@import "../../../globalStyles/scss/variables.scss";

.menu-dropdown {
  position: relative;

  &__body {
    display: none;
    position: absolute;
    right: 1rem;
    top: 0;
    box-shadow: $message-shadow;
    background-color: $white;
    padding: $spacer;
    width: 15rem;

    &__rightdown{
      right: 0rem!important;
      left: 1rem!important;
    }
    
    &--visible {
      display: block;
      z-index: 2;
    }

    ul {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: normal;

      line-height: 2rem;
      align-items: flex-start;

      li {
        width: 90%;
      }
    }
  }
}
