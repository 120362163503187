@import "../../../globalStyles/scss/variables.scss";

.header-with-dropdown {
  z-index: 3;
}

.banner {
  &__static {
    text-align: center;
    background-color: #ffcc01;
    padding: 0;
    margin: 0 0 25px;
    img {
      max-height: 700px;
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
  }
}

.main-menu {
  align-items: center;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: 3.55rem;
  position: relative;

  &__left {
    flex-basis: calc(50% - 2rem);

    @media (min-width: $small-screen) {
      flex-basis: calc(50% - 4rem);
    }

    ul {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__right {
    flex-basis: calc(50% - 2rem);

    @media (min-width: $small-screen) {
      flex-basis: calc(50% - 4rem);
    }

    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__center {
    align-self: center;

    svg {
      width: 4rem;

      @media (min-width: $small-screen) {
        width: 6rem;
      }
    }
  }

  &__item {
    font-weight: $bold-font-weight;
    height: 100%;
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: $white;
      position: relative;
      transition: 0.3s;
      font-weight: $bold-font-weight;
    }

    &:hover {
      a {
        color: $black;
      }
    }
  }

  &__hamburger {
    //border-right: 1px solid $gray-light;
    cursor: pointer;
    padding: $spacer;

    &--hover {
      display: none;
    }

    &:hover {
      .main-menu__hamburger--icon {
        display: none;
      }

      .main-menu__hamburger--hover {
        display: block;
      }
    }

    svg {
      vertical-align: bottom;
    }

    &--icon {
      path {
        fill: $white;
      }
    }
  }

  &__item,
  &__hamburger {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__icon {
    padding: 0 $spacer;
    cursor: pointer;

    svg * {
      transition: 0.3s;
      fill: $white;
    }

    &:hover {
      svg * {
        fill: $yellow;
      }
    }

    svg {
      vertical-align: bottom;
    }
  }

  &__cart {
    position: relative;

    &__quantity {
      position: absolute;
      top: -0.3rem;
      right: 0.6rem;
      background-color: $yellow;
      border-radius: 1rem;
      padding: 0 0.3rem;
      min-width: 1rem;
      height: 1rem;
      color: $black;
      font-size: $small-font-size;
      text-align: center;
      line-height: 1rem;
      transition: 0.3s;
    }
  }

  &__user {
    &--active * {
      fill: $white;
    }
  }

  &__dropdown {
    text-transform: uppercase;
    font-weight: $bold-font-weight;

    li {
      cursor: pointer;
      margin: 0;
      padding: 0 10px;

      &:hover {
        color: $black;
        background-color: $yellow;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $gray-light;
      }
    }
  }

  &__offline {
    line-height: 24px;
    padding: $spacer;

    span {
      text-transform: uppercase;
      font-weight: $bold-font-weight;
      vertical-align: bottom;
    }
  }

  &__search {
    padding: $spacer;
    cursor: pointer;
    line-height: 24px;
    transition: 0.3s;
    color: $white;

    svg * {
      transition: 0.3s;
      fill: $white;
    }

    &:hover {
      color: $yellow;
      svg * {
        fill: $yellow;
      }
    }

    span {
      text-transform: uppercase;
      font-weight: $bold-font-weight;
      margin-right: $spacer;
    }

    div {
      display: inline-block;
    }

    svg {
      vertical-align: bottom;
    }
  }

  &__nav-dropdown {
    padding: 0 1.5rem;

    li {
      span {
        color: $white;

        &:hover {
          color: $black;
        }
      }
    }

    &:hover {
      li {
        span {
          color: $black;
        }
      }

      background-color: $gray-light;
    }

    &--active {
      background-color: $gray-light;

      li {
        span {
          color: $black;
        }
      }
    }

    & > li:first-child {
      text-align: center;
    }

    &__body {
      background: $white;
      border-top-color: $gray-light;
      border-top-style: solid;
      border-top-width: 1px;
      display: none;
      left: 0;
      padding: 2rem;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 3;

      &--visible {
        display: block;
      }

      li *,
      ul * {
        display: block !important;
      }

      > ul > li {
        align-self: flex-start;
        margin-left: 2rem;
        position: relative;
        width: 188px;

        // 2nd level name
        > a {
          font-size: $base-font-size;
          position: relative;
          text-transform: uppercase;
          padding-bottom: 6px;
        }
        > ul > li:first-child {
          position: relative;
        }

        ul {
          // 3rd+ level names
          a {
            padding: 6px 0;
          }

          li > ul {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
