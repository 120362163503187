@import "../../../globalStyles/scss/variables.scss";

.products-featured {
  padding: 2rem 0 4rem;

  h3 {
    font-weight: $bold-font-weight;
    margin-bottom: $spacer * 2;
    text-transform: uppercase;
  }

  a {
    color: $base-font-color;
    display: inline-block;
    max-width: 255px;
    text-decoration: none;
  }
}
