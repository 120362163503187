@use "sass:math";

@import "../../../globalStyles/scss/variables.scss";

.social-icon {
  padding: $spacer math.div($spacer, 2);

  svg * {
    fill: $yellow;
    transition: 0.3s;
  }

  &:hover {
    svg * {
      fill: $white;
    }
  }
}
