@import "../../../globalStyles/scss/variables.scss";

.side-nav {
  $item-height: 3.7rem;
  max-width: calc(100vw - 0.5rem);
  width: 30rem;
  overflow-y: auto;
  height: 100vh;

  ul {
    padding-bottom: 8rem;
  }

  &__menu-item {
    //border-bottom: 1px solid $gray-light;
    display: grid;
    height: $item-height;

    &-link,
    &-back span {
      align-items: center;
      color: $base-font-color;
      display: flex;
      font-weight: 600;
      padding: $spacer;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.3s;

      svg * {
        fill: currentColor;
      }
    }

    &-link {
      padding-right: 0;
    }

    &-logo {
      align-self: center;
      padding: 0.75rem $spacer;

      svg {
        width: 5rem;

        path {
          fill: $font-black;
        }
      }
    }

    &-link,
    &-more,
    &-back,
    &-close {
      transition: 0.3s;

      &:hover {
        color: $font-black;
      }

      svg * {
        fill: currentColor;
      }
    }

    &-close,
    &-more,
    &-back {
      &:hover {
        cursor: pointer;
      }
    }

    &-more {
      background-color: $yellow;
      align-self: center;
      padding: 1.25rem 2rem 1.25rem 2rem;

      svg {
        transform: scale(1.75);
      }
    }

    &-back {
      svg {
        margin-right: 1rem;
      }
    }

    &-close {
      align-items: center;
      display: flex;
      height: $item-height;
      justify-content: center;

      &:hover {
        color: $font-black;
      }
    }

    &--has-subnavigation {
      grid-template-columns: auto 4.25rem;
    }

    &--parent {
      grid-template-columns: 1fr $item-height;
    }

    &--parent {
      color: $gray;

      a {
        color: inherit;
      }
    }
  }
}
