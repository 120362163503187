@import "../../../globalStyles/scss/variables.scss";

.product-filters {
  background-color: $gray-light;

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $spacer * 2;
    padding: $spacer 0;

    @media (max-width: $medium-screen) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $small-screen) {
      grid-template-columns: 1fr;
    }
  }
}
