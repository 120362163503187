@import "../../../globalStyles/scss/variables.scss";
.account {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-bottom: 2rem;

  @media screen and (max-width: $small-screen) {
    flex-direction: column;
  }

  &__menu {
    width: 360px;
    margin-right: 2rem;
  }

  &__content {
    width: 100%;
  }
}
