@import "../../../globalStyles/scss/variables.scss";

.address-summary {
  color: $gray;
  line-height: 1.5;
  font-size: $small-font-size;

  strong {
    font-weight: $bold-font-weight;
    display: inline-block;
    padding-bottom: $spacer * 0.5;
  }
}
