@import "../../../globalStyles/scss/variables.scss";

button.button {
  background-color: $yellow;
  transform: $button-transform;
  padding: $button-padding;
  border: none;
  box-shadow: $button-box-shadow;
  transition: $button-transition;
  outline: none;
  font-family: $base-font-family;
  cursor: pointer;

  @media (max-width: $small-screen) {
    padding: $button-padding-small-screen;
    width: 88%;
    max-width: 88%;
  }

  > span {
    display: inline-block;
    color: $black;
    font-size: $button-font-size;
    text-transform: $button-text-transform;
    font-weight: $button-font-weight;
    line-height: $button-line-height;
    transform: $button-content-transform;
  }

  &:hover {
    background-color: $yellow-light-dark;
  }

  &:active {
    box-shadow: $button-box-shadow-clicked;
    background-color: $yellow-dark;
  }

  &.secondary {
    background-color: $white;
    box-shadow: inset 0px 0px 0px 3px $black;
    border-left: 1px solid $black;
    border-right: 1px solid $black;

    * {
      color: $black;
    }

    &:hover {
      background-color: $black;

      * {
        color: $yellow;
      }
    }

    &:active {
      color: $yellow-dark;
    }
  }

  &:disabled {
    background-color: $gray;

    &,
    &:hover {
      cursor: default;
    }
  }
}
