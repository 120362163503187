@import "../../../globalStyles/scss/variables";

.inpost-geopicker-widget-container {
  input {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
  span {
    line-height: 50px;
    display: block;
  }
}
