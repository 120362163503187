@use "sass:math";

@import "../../../globalStyles/scss/variables.scss";

.breadcrumbs {
  display: inline-flex;
  flex-wrap: wrap;
  margin: $spacer 0;

  a {
    color: $gray-light;
  }

  li {
    &:not(:last-of-type) {
      &:after {
        content: url("../../../images/breadcrumbs-arrow.svg");
        display: inline-block;
        margin: 0 math.div($spacer, 2);
      }
    }

    a {
      color: $gray-light;
      text-decoration: none;
      vertical-align: middle;
      transition: 0.3s;

      &:hover,
      &:focus {
        color: $yellow;
      }
    }
  }

  &__active {
    a {
      color: $base-font-color;
      font-weight: $bold-font-weight;

      &:hover {
        color: $yellow;
      }
    }
  }
}
