@use "sass:math";

@import "../../../globalStyles/scss/variables.scss";

.product-page {
  //background-color: $white;

  .breadcrumbs {
    //background-color: $white;
    padding: math.div($spacer, 2) $spacer math.div($spacer, 2) $spacer * 1.5;
    margin-top: $spacer * 2;
  }

  &__product {
    display: flex;
    margin-top: $spacer;
    padding-bottom: $spacer * 5;

    @media (max-width: $small-screen) {
      display: block;
      margin-bottom: $spacer * 2;
    }

    &__description {
      width: 100%;
      min-height: $spacer * 10;
      margin-bottom: $spacer * 4;
    }

    &__info {
      flex-grow: 1;
      width: 30%;
      position: relative;

      @media (max-width: $medium-screen) {
        width: 30%;
      }

      @media (max-width: $small-screen) {
        width: 100%;
      }

      > div {
        @media (min-width: $small-screen) {
          padding-right: 1rem;
        }
      }

      &--fixed {
        top: 0;
        position: sticky;
        width: auto;

        @media (min-width: $container-width) {
          width: calc(#{$container-width} * 30 / 100);
        }
      }
    }

    &__gallery {
      flex-grow: 2;
      width: 60%;
      padding: 0 $spacer 0 0;
      box-sizing: border-box;

      @media (max-width: $medium-screen) {
        width: 50%;
      }

      @media (max-width: $small-screen) {
        width: 100%;
        padding: 0;
        margin-bottom: $spacer * 2;
        padding: 0;
      }

      &__nav {
        li {
          display: inline-block;
          padding: math.div($spacer, 2);

          span {
            width: math.div($spacer, 2);
            height: math.div($spacer, 2);
            border: 1px solid $gray;
            border-radius: math.div($spacer, 2);
            display: block;
          }

          &.active {
            span {
              background-color: $turquoise;
              border-color: $turquoise;
            }
          }
        }
      }
    }
  }

  &__other-products {
    padding: $spacer * 2 0 $spacer * 4;

    &__title {
      color: $gray-light;
      text-transform: uppercase;
      font-weight: $bold-font-weight;
      margin-bottom: $spacer * 2;
    }
  }
}
