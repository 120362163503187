@import "../../../globalStyles/scss/variables.scss";

.article-page {
  &__container {
    color: $gray-light;
    //column-gap: $spacer * 1.5;
    display: grid;
    //grid-template-columns: repeat(3, 1fr);
    margin: 60px 0;

    @media (max-width: $medium-screen) {
      display: block;
    }
  }

  &__header {
    text-align: center;
    padding-top: $spacer * 4.5;
    padding-bottom: $spacer * 4.5;
    background-size: cover;
    background-color: $blue-overlay;
    background-blend-mode: multiply;

    &__title {
      background-color: $white;
      display: inline-block;
      //transform: skew(45deg);
      padding: 1.5vw 9vw;

      h1 {
        display: inline-block;
        //transform: skew(-45deg);
        font-weight: $bold-font-weight;
        text-transform: uppercase;
        color: $black;
        font-size: 8vw;
        font-weight: 900;

        @media (min-width: $small-screen) {
          font-size: 4vw;
        }
      }
    }
  }

  &__content {
    grid-column-end: span 2;

    h1,
    h2,
    h3 {
      font-weight: 600;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: $spacer * 1.5;
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: $spacer;
    }

    img {
      max-width: 100%;
    }

    ol,
    ul {
      margin: 1.875rem auto;
      padding-left: 0;
      font-size: 1.125rem;
      line-height: 1.7;
      list-style: none;
      li {
        padding-left: 1.3rem;
        margin-left: 0.3rem;
        margin-bottom: 1rem;
      }
    }

    ol {
      position: relative;
      counter-reset: my-awesome-counter;
      li {
        counter-increment: my-awesome-counter;
        &:before {
          content: counter(my-awesome-counter) ".";
          position: absolute;
          left: 0;
        }
      }
    }

    ul {
      list-style: none;
      li {
        position: relative;
        &:before {
          position: absolute;
          top: 0.7rem;
          left: 0;
          content: "";
          display: inline-block;
          width: 0.375rem;
          height: 0.375rem;
          border-radius: 50%;
          background-color: $darkGreen;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    blockquote {
      position: relative;
      font-size: 1.125rem;
      font-style: italic;
      background-color: $turquoise-light;
      border-left: 0.5rem solid $darkGreen;
      margin: 1.875rem auto;
      padding: 3.75rem 2.5rem 3rem 2.5rem;
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 1.5rem;
        width: 1.44rem;
        top: 1.56rem;
        background-image: url("../../../images/quote-icon.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  &__navigation {
    @media (max-width: $medium-screen) {
      margin-bottom: $spacer * 2;
    }

    &-element {
      font-family: "Montserrat";
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;

      &--active {
        a {
          display: inline-block;
          position: relative;

          &:before {
            background-color: $turquoise;
            content: "";
            height: 7px;
            left: 0;
            position: absolute;
            top: 6px;
            transform: skew(45deg);
            width: 100%;
            z-index: -1;
          }
        }
      }
    }
  }
}
