@use "sass:math";

@import "../../../globalStyles/scss/variables.scss";

.cart-table {
  @media (max-width: $medium-screen) {
    td:last-child,
    th:last-child {
      text-align: right;
    }
  }

  &-row--processing td {
    position: relative;

    &::after {
      background-color: rgba($white, 0.65);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &__cost {
    color: $gray;
    font-weight: $bold-font-weight;
    text-transform: uppercase;
  }

  &__quantity {
    &-header {
      text-align: center;
    }

    &-cell {
      text-align: center;

      div {
        align-items: center;
        display: flex;
        justify-content: space-around;

        @media (max-width: $small-screen) {
          flex-direction: column-reverse;
        }

        &.disabled {
          opacity: 0.8;

          &:hover svg {
            cursor: default;
          }
        }
      }

      & > div {
        padding: math.div($spacer, 2);
      }
    }
  }

  svg:hover {
    cursor: pointer;
  }

  &__thumbnail {
    div {
      display: flex;
      align-items: center;
    }

    img {
      width: 50px;
      height: auto;
    }
  }

  &__checkout-action {
    text-align: right;
    margin: 0 $spacer * 2 $spacer * 3 0;

    @media (max-width: $small-screen) {
      text-align: center;
    }
  }

  &__empty {
    text-align: center;
    padding: $spacer * 5 0;

    h4 {
      font-weight: $bold-font-weight;
      text-transform: uppercase;
      margin-bottom: $spacer;
    }

    p {
      color: $gray;
    }

    &__action {
      text-align: center;
      margin-top: $spacer;
    }
  }
}
