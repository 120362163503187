@use "sass:math";

@import "../../../globalStyles/scss/variables.scss";

.search-page {
  &__header {
    padding: $spacer * 4 0;

    &__input {
      .input {
        &__label {
          text-transform: uppercase;
          font-size: $h3-font-size;
          font-weight: $extra-bold-font-weight;
        }

        &__field {
          border: none;
          box-shadow: none;
          border-bottom: 1px solid $white;
          font-size: $h1-font-size;
          padding: math.div($spacer, 2) 0;
          color: $white;
          text-transform: uppercase;
          font-weight: $extra-bold-font-weight;

          &:focus {
            box-shadow: none;
            color: $white;
          }
        }
      }
    }
  }
}
