.c- {
  &option {
    cursor: pointer;
    padding: $spacer $spacer $spacer $spacer * 2.5;
    position: relative;

    label {
      cursor: pointer;

      &::before {
        display: block;
        position: absolute;
        content: "";
        border-radius: 100%;
        height: 0.5rem;
        width: 0.5rem;
        top: $spacer * 1.25;
        left: $spacer;
        margin: auto;
        box-shadow: 0 0 0 1px $blue;
        border: 2px solid $white;
        background-color: $white;
      }
    }

    &--selected {
      label::before {
        background-color: $blue;
      }
    }

    &--disabled {
      label::before {
        background-color: rgba($gray, 0.5);
        box-shadow: 0 0 0 1px $gray;
      }
    }

    input {
      display: none;
    }
  }
}
