@import "../../../globalStyles/scss/variables.scss";

.category {
  &__header {
    text-align: center;
    padding-top: $spacer * 4.5;
    padding-bottom: $spacer * 4.5;
    background-size: cover;
    background-color: $blue-overlay;
    background-blend-mode: multiply;

    &__title {
      background-color: $white;
      display: inline-block;
      transform: skew(-45deg);
      padding: 1.5vw 9vw;

      h1 {
        display: inline-block;
        transform: skew(45deg);
        font-weight: $bold-font-weight;
        text-transform: uppercase;
        color: $blue;
        font-size: 8vw;
        font-weight: 900;

        @media (min-width: $small-screen) {
          font-size: 4vw;
        }
      }
    }
  }
}
